import React from 'react';
import {
  Slider,
  withStyles,
} from '@material-ui/core';

export const TimeSlider = withStyles((theme) => ({
    root: {
      color: theme.palette.secondary.main,
      height: 3,
      padding: '13px 0',
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: theme.palette.background.default,
      border: '4px solid currentColor',
      marginTop: -8,
      marginLeft: -13,
    //   '&:focus,&:hover,&$active': {
    //     boxShadow: '#ccc 0px 2px 3px 1px',
    //   },
      '& .bar': {
        height: 9,
        width: 1,
        backgroundColor: 'currentColor',
        marginLeft: 1,
        marginRight: 1,
      },
    },
    valueLabel: {
      color: 'transparent',
      fontWeight: 'bold',
      background: 'transparent',
      left: 'calc(-50% - 4px)',
      top: '-23px',
  
    },
    track: {
      height: 3,
    },
    rail: {
      color: theme.palette.secondary.main,
      opacity: 1,
      height: 3,
    },
  }))((props) => <Slider
    marks
    valueLabelDisplay='on'
    valueLabelFormat={value => `${value}:00`}
    defaultValue={[8, 22]}
  {...props}
  />);