import React, { ChangeEvent } from 'react';
import {
  Tab,
  Tabs,
  Box
} from '@material-ui/core';
import { useTranslation } from 'react-i18next'

export const ViewSwitcherTabs = (props: {
    currentTab: number;
    onChangeTab: (tabIndex: number) => void;
}) => {
    const { t } = useTranslation();
    return (
        <Tabs
            value={props.currentTab}
            onChange={(_: ChangeEvent<{}>, tabIndex) => {
                props.onChangeTab(tabIndex)
            }}
            variant='fullWidth'
        >
            <Tab label={t('map.list')}/>
            <Tab label={t('map.map')}/>
        </Tabs>
    )
}