import React from 'react';
import {
    makeStyles,
    Button,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useCitySelectionButtonStyles = makeStyles(theme => ({
    button: {
      border: 'none',
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h5.fontSize
    },
}));
  
export const CitySelectionButton = ({
    label,
    onClick
  }: {
    label: string;
    onClick: () => void
  }) => {
    const classes = useCitySelectionButtonStyles();
    return (
      <Button
        className={classes.button}
        endIcon={<ExpandMore/>}
        onClick={onClick}>
          {label}
      </Button>
    )
  }
  