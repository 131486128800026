import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { latLngBounds } from 'leaflet'
import { Map, TileLayer, Marker, Viewport } from 'react-leaflet';
import { LatLngTuple } from 'leaflet'
import { createGMDMapIcon, createGMDMapIconSelected} from 'components/map-icon'

import { isBrowser } from 'utilities/gatsby'

const useStyles = makeStyles(theme => ({
    mapContainer: {
      width: '100%',
      height: '100%'
    },
  }));

const resetMapSizeOnCitySelect = (selectedCityId: any, isMapViewActive: boolean) => {
    /**
     * Leaflet fails to initialize properly on mobile,
     * because initially the map container is hidden.
     * To fix this, we invalidate the size after the
     * city is selected and the view is switched to the map.
     */
    const mapRef = createRef();
    useEffect(() => {
      if (selectedCityId && mapRef.current || isMapViewActive === true)  {
        mapRef.current.leafletElement.invalidateSize();
      }
    }, [selectedCityId, isMapViewActive])
    return mapRef
}

export const MapView = (props: {
    locations: Location[],
    isMapViewActive: boolean,
    selectedLocationId: any,
    selectedCityId: any,
    selectedZoneId: any,
    onSelectLocation: (locationId: any) => void;
    onDeselectLocation: () => void;
  }) => {
    const classes = useStyles(props);
    if (!isBrowser()) {
      return null
    }
    const mapRef = resetMapSizeOnCitySelect(props.selectedCityId, props.isMapViewActive);
    const initialPosition: LatLngTuple = [55.3661, 23.8868];
    const markerData = props.locations && props.locations.map(({id, coordinates}) => {
        const lat = parseFloat(coordinates.split(',')[0]);
        const lng = parseFloat(coordinates.split(',')[1]);
        const latLngTuple: LatLngTuple = [lat, lng];
        return {
          id,
          latLngTuple
        }
    });
    let bounds;
    if (markerData && markerData.length > 1) { // Bounds crash with a single point
      bounds = latLngBounds(markerData.map(({latLngTuple}) => latLngTuple));
    }
    const selectedLocation =
      markerData && markerData.find(({id}) => id === props.selectedLocationId);
    const [ state, setState ] = useState({
        lastSelectedLocation: null,
        lastZoomLevel: null,
    });

    let center;
    let zoom;
    if (selectedLocation) {
        center = selectedLocation.latLngTuple;
    } else if (state.lastSelectedLocation) {
        center = state.lastSelectedLocation.coordinates;
        zoom = state.lastZoomLevel;
    } else {
        center = initialPosition;
        zoom = 6.5;
    }
    return (
      <Map
        ref={(map) => { mapRef.current = map; }}
        bounds={bounds}
        onClick={() => {
          props.onDeselectLocation();
        }}
        style={{
          height: '100%'
        }}
        zoom={zoom}
        // viewport={viewport}
        onViewportChanged={(viewport: Viewport) => {
          setState({
              ...state,
              lastZoomLevel: viewport.zoom
          })
        }}
        zoom={!markerData ? 6.5 : undefined}
        zoom={zoom}
        center={center}
        className={classes.mapContainer}
          >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {
          props.locations && props.locations.map(({id, coordinates, name, zone}) => {
            const lat = parseFloat(coordinates.split(',')[0]);
            const lng = parseFloat(coordinates.split(',')[1]);
            const markerPosition: LatLngTuple = [lat, lng];
            return (
              <Marker
                icon={props.selectedLocationId === id ? createGMDMapIconSelected() : createGMDMapIcon() }
                onClick={() => {
                  setState({
                      ...state,
                      lastSelectedLocation: {id, name, coordinates: markerPosition, zoneName: zone.name}
                    });
                  props.onSelectLocation(id);
                }}
                key={id}
                position={markerPosition}/>
            )
          })
        }
      </Map>
    )
  }
