import React, { useState, ChangeEvent } from 'react';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next'
import { Layout } from 'components/layouts/main-layout';
import { useQueryVisibleCities } from 'components/admin/common/city-selector';
import { City } from 'types'
import { TimeSlider } from 'components/map-page/TimeSlider';
import { ArtistInfoPopout } from 'components/map-page/ArtistInfoPopout';
import { ListView } from 'components/map-page/ListView';
import { MapView } from 'components/map-page/MapView';
import { LocationBar } from 'components/map-page/LocationBar';
import { CitySelectionButton } from 'components/map-page/CitySelectionButton';
import { ViewSwitcherTabs } from 'components/map-page/ViewSwitcherTabs';
import { SearchAndZoneFilters } from 'components/map-page/SearchAndZoneFilters';
import { useDebounce } from 'components/map-page/useDebounce';
import { Performance, Zone } from 'components/map-page/types';
import { isBrowser } from 'utilities/gatsby';

const client = new ApolloClient({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
});


const useStyles = makeStyles(theme => ({
  eventTemplateContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    position: 'relative',
    flex: 2,
    [theme.breakpoints.up('md')]: {
      maxHeight: '80vh',
      maxWidth: '75rem',
      margin: 'auto'
    },
  },
  inActiveView: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  locationBarContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%'
    },
  },
}));

const EventMapTemplate = (props: {
  cities: City[];
  zones: Zone[];
  genres: {
    id: string,
    name: string,
    name_en: string,
  }[];
  locations: Location[];
  performances: Performance[];
  selectedPerformance: Performance;
  currentTab: 0 | 1; // list view or the map view on mobile
  isCityListShown: boolean;
  isPerformancesFetching: boolean;
  selectedLocation: Location[];
  selectedCityId: any;
  selectedCity: City;
  selectedCityName: string;
  selectedLocationName: string;
  selectedLocationZoneName: string;
  selectedLocationId: any;
  selectedGenreId: any;
  searchQuery: any;
  hourFrom: number;
  hourTo: number;
  onChangeTab: (currentTab: 0 | 1) => void;
  onResetCity: () => void;
  onResetPerformance: () => void;
  onResetLocation: () => void;
  onSelectCity: (cityId: any) => void;
  onSelectZone: (zoneId: any) => void;
  onSelectGenre: (genreId: any) => void;
  onSelectLocation: (locationId: any) => void;
  onSelectPerformance: (perfomanceId: any) => void;
  onSelectListView: () => void;
  onSelectSearch: () => void;
  onSelectMapView: () => void;
  onChangeTime: (timeValues: number[]) => void;
  onSearch: (query: string) => void;
}) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  return (
    <Layout
      centered
      fullWidth
      headerChildren={
        props.selectedCityName &&
        <CitySelectionButton
          label={props.selectedCityName}
          onClick={() => {
            props.onResetCity();
          }}
        />
      }
      pageName={t('page.map')}>
      <Grid container className={classes.eventTemplateContainer} item justify='center'>
        {props.selectedCityId && <Grid container item direction='column'>
          <Box style={{ width: '100%' }}>
            <Grid container justify='center'>
              <Grid item container xs={12} sm={5} spacing={1}>
                <Hidden smDown >
                  <Grid container item xs={12} justify='center'>
                    <CitySelectionButton label={props.selectedCityName} onClick={() => {
                      props.onResetCity();
                    }}
                    />
                  </Grid>
                </Hidden>
                <SearchAndZoneFilters
                  zones={props.zones}
                  genres={props.genres}
                  searchQuery={props.searchQuery}
                  selectedZoneId={props.selectedZoneId}
                  selectedGenreId={props.selectedGenreId}
                  onClickSearch={() => {
                    props.onSelectSearch();
                  }}
                  onSearch={(searchQuery) => {
                    props.onSearch(searchQuery)
                  }}
                  onSelectZone={(zoneId) => {
                    props.onSelectZone(zoneId)
                  }}
                  onSelectGenre={(genreId) => {
                    props.onSelectGenre(genreId)
                  }}

                />
              </Grid>
              <Grid container item alignContent='flex-end' xs={12} sm={7}>
                <Box pt={3} pl={3} pr={3} style={{ width: '100%' }}>
                  <TimeSlider
                    min={props.selectedCity && props.selectedCity.hourFrom}
                    max={props.selectedCity && props.selectedCity.hourTo}
                    onChangeCommitted={(e: ChangeEvent<{}>, values: number[]) => {
                      props.onChangeTime(values);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box width={'100%'} display={{ xs: 'block', sm: 'none' }}>
            <ViewSwitcherTabs
              currentTab={props.currentTab}
              onChangeTab={props.onChangeTab}
            />
          </Box>
        </Grid>
        }
        <Grid item container style={{
          flexGrow: 4,
          height: '100%',
          flexBasis: 0,
        }}>
          <Grid
            item
            xs={12}
            sm={5}
            style={{
              minHeight: 0,
              flexBasis: 0,
              flexGrow: 1,
              overflowY: 'auto'
            }}
            className={
              clsx({
                [classes.inActiveView]: props.currentTab === 1,
              })
            }>
            <ListView
              performances={props.performances}
              selectedPerformance={props.selectedPerformance}
              isCityListShown={props.isCityListShown}
              isPerformancesFetching={props.isPerformancesFetching}
              selectedLocationName={props.selectedLocationName}
              selectedZoneName={props.selectedLocationZoneName}
              onResetLocation={props.onResetLocation}
              onSelectPerformance={props.onSelectPerformance}
              onSelectCity={props.onSelectCity}
              onToggleMapView={props.onSelectMapView}
              onNavigate={() => {
                window.open(`geo:${props.selectedLocation.coordinates}`, '_blank');
              }}

            />
          </Grid>
          {
            props.selectedPerformance &&
            <ArtistInfoPopout
              performance={props.selectedPerformance}
              onShowMap={() => {
                const { id } = props.selectedPerformance.location;
                props.onSelectLocation(id);

              }}
              onClose={() => {
                props.onResetPerformance();
              }}
            />
          }
          {
            props.selectedLocationName &&
            !props.selectedPerformance &&
            props.currentTab === 1 &&
            <Box
              className={classes.locationBarContainer}
              style={{
                position: 'absolute',
                zIndex: 2000,
                bottom: 0,
                right: 0,
              }}
            >
              <LocationBar
                isListViewActive={props.currentTab === 0}
                locationName={props.selectedLocationName}
                locationZone={props.selectedLocationZoneName}
                onToggleListView={() => {
                  props.onSelectListView();
                }}
                onToggleMapView={() => {
                  props.onSelectMapView();
                }}
                onNavigate={() => {
                  window.open(`geo:${props.selectedLocation.coordinates}`, '_blank');
                }}
                onResetLocation={() => {
                  props.onResetLocation();
                }}
              />
            </Box>
          }
          <Grid
            item
            xs={12}
            sm={7}
            className={
              clsx({
                [classes.inActiveView]: props.currentTab === 0,
              })
            }>
            <MapView
              locations={props.locations}
              isMapViewActive={props.currentTab === 1}
              selectedZoneId={props.selectedZoneId}
              selectedLocationId={props.selectedLocationId}
              selectedCityId={props.selectedCityId}
              onSelectLocation={props.onSelectLocation}
              onDeselectLocation={props.onResetLocation}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

const FIND_LOCATIONS = gql`
    query locations($city: ID!, $zoneId: ID,) {
        locations (where: { city: $city, zone: $zoneId } ) { id, name, description, coordinates, zone { name } }
    }
`

const FIND_ZONES = gql`
    query zones($city: ID!) {
        zones (where: { city: $city } ) { id, name }
    }
`
const FIND_GENRES = gql`
{
  genres {id, name, name_en, name_ua }
}`;

const FIND_PERFORMANCES = gql`
    query performances($city: ID!, $hourFrom: Float, $hourTo: Float, $location: ID, $searchQuery: String, $zone: ID, $genre: ID ) {
        performances (
          sort: "hourFrom:asc",
          where:  {
            hourFrom_gte: $hourFrom,
            hourTo_lte: $hourTo,
            performer: {
              genre: $genre,
              name_contains: $searchQuery
              participant: {
                verification_status: "confirmed"
              }
            },
            location: {
              id: $location
              city: $city
              zone: $zone
        }} ) { id, hourFrom, hourTo, location { id, coordinates, name, zone { name } }, performer { id, name, about, website, image { url }, genre { name, name_en, name_ua }} }
    }
`
const useBrowserHistoryState = <S extends unknown>(initialState: S): [S, (state: S) => void] => {
  const [state, setState] = useState<S>(initialState);
  if (!isBrowser()) {
    return [state, setState];
  }
  const history = window.history;
  React.useEffect(() => {
    window.onpopstate = (event: PopStateEvent) => {
      if (event.state === null) {
        setState(initialState);
      } else {
        setState(event.state);
      }
    };
  }, [initialState])
  const wrappedSetState = (newState: S) => {
    history.pushState(newState, '')
    setState(newState);
  }
  return [state, wrappedSetState];
}
const EventMap = (props) => {
  const [state = {}, setState] = useBrowserHistoryState<{
    hourFrom: number;
    hourTo: number;
    currentTab: 0 | 1;
    searchQuery: string;
    selectedCityId: any;
    selectedLocationId: any;
    selectedZoneId: any;
    selectedPerformanceId: any;
  }>({
    hourFrom: 8,
    hourTo: 22,
    searchQuery: '',
    currentTab: 0, // list view
    selectedCityId: undefined,
    selectedLocationId: undefined,
    selectedZoneId: undefined,
    selectedPerformanceId: undefined,
  });
  const debouncedSearchTerm = useDebounce(state.searchQuery, 200);
  const {
    data: performanceData,
    loading: isPerformancesFetching
  } = useQuery<{ performances: Performance[] }>(FIND_PERFORMANCES, {
    variables: {
      city: state.selectedCityId,
      location: state.selectedLocationId,
      hourFrom: state.hourFrom,
      hourTo: state.hourTo,
      searchQuery: debouncedSearchTerm,
      zone: state.selectedZoneId,
      genre: state.selectedGenreId
    }
  });
  const { data: cityData } = useQueryVisibleCities();
  const {
    data: zoneData,
  } = useQuery<{ zones: Zone[] }>(FIND_ZONES, {
    variables: {
      city: state.selectedCityId,
      skip: state.selectedCityId === undefined
    }
  });
  const {
    data: genreData,
  } = useQuery<{
    genres: {
      id: string;
      name: string;
      name_en: string
    }[]
  }>(FIND_GENRES);

  const {
    data: locationData,
  } = useQuery<{ locations: Location[] }>(FIND_LOCATIONS, {
    variables: {
      city: state.selectedCityId,
      zoneId: state.selectedZoneId
    }
  });
  const selectedCity = cityData && cityData.cities.find(({ id }) => id == state.selectedCityId);
  const selectedLocation = locationData && locationData.locations.find(({ id }) => id == state.selectedLocationId);
  const selectedPerformance = performanceData && performanceData.performances.find(({ id }) => id == state.selectedPerformanceId);
  return <EventMapTemplate
    cities={cityData && cityData.cities}
    zones={zoneData && zoneData.zones}
    genres={genreData && genreData.genres}
    locations={locationData && locationData.locations}
    selectedLocation={selectedLocation}
    selectedCityId={state.selectedCityId}
    selectedCity={selectedCity}
    selectedCityName={selectedCity?.name}
    selectedZoneId={state.selectedZoneId}
    selectedGenreId={state.selectedGenreId}
    selectedLocationId={state.selectedLocationId}
    selectedLocationName={selectedLocation?.name}
    selectedLocationZoneName={selectedLocation?.zone.name}
    isCityListShown={!state.selectedCityId}
    isPerformancesFetching={isPerformancesFetching}
    searchQuery={state.searchQuery}
    currentTab={state.currentTab}
    selectedPerformance={selectedPerformance}
    performances={performanceData && performanceData.performances}
    hourFrom={state.hourFrom}
    hourTo={state.hourTo}
    onChangeTab={(currentTab) => {
      setState({
        ...state,
        currentTab
      });
    }}
    onResetLocation={() => {
      setState({
        ...state,
        selectedLocationId: undefined
      });
    }}
    onResetCity={() => {
      setState({
        ...state,
        currentTab: 0, // switch to city list
        selectedLocationId: undefined,
        selectedCityId: undefined,
        selectedZoneId: undefined,
        searchQuery: undefined,
      });
    }}
    onResetPerformance={() => {
      setState({
        ...state,
        selectedPerformanceId: undefined
      });
    }}
    onSelectPerformance={(performanceId) => {
      setState({
        ...state,
        selectedPerformanceId: performanceId
      });
    }}
    onSelectListView={() => {
      setState({
        ...state,
        currentTab: 0
      });
    }}
    onSelectMapView={() => {
      setState({
        ...state,
        currentTab: 1
      });
    }}
    onChangeTime={(values: number[]) => {
      const [hourFrom, hourTo] = values;
      setState({
        ...state,
        hourFrom,
        hourTo
      })
    }}
    onSelectCity={(cityId) => {
      setState({
        ...state,
        currentTab: 1, // switch to map view
        selectedCityId: cityId
      })
    }}
    onSelectZone={(zoneId) => {
      setState({
        ...state,
        selectedZoneId: zoneId === '' ? undefined : zoneId
      })
    }}
    onSelectGenre={(genreId) => {
      setState({
        ...state,
        selectedGenreId: genreId === '' ? undefined : genreId
      })
    }}
    onSelectLocation={(locationId) => {
      setState({
        ...state,
        currentTab: 1,
        selectedPerformanceId: undefined,
        selectedLocationId: locationId,
      })
    }}
    onSelectSearch={() => {
      setState({ // relevant for mobile
        ...state,
        currentTab: 0,
        selectedLocationId: undefined,
        selectedPerformanceId: undefined
      });
    }}
    onSearch={(searchQuery: string) => {
      setState({
        ...state,
        searchQuery
      })
    }}
  />
}

export default () => {
  return (
    <ApolloProvider client={client}>
      <EventMap />
    </ApolloProvider>
  )
}
